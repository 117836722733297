import React, { useContext } from 'react'
import { navigate } from '@reach/router'
import { Button, Text, margin, padding, position, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import Hero from '../../../../components/Hero'
import Layout from '../../../../components/Layout'

const HealthcareCashPlan = () => {
  const user = useContext(UserContext)
  const { Loaded, Steps } = user
  const userTaskHealthcareCashPlanForm = Steps.find(step => step.Step.Id === 11)
  const isCompleted = userTaskHealthcareCashPlanForm && userTaskHealthcareCashPlanForm.Completed
  return Loaded ? (
    <Layout>
      <Hero icon="person">
        Healthcare cash plan
      </Hero>

      <ContentWrap util={[padding.v3, padding.v5Sm]}>
        <Text>
          We are excited to provide our employees access to a healthcare cash plan through Simplyhealth.
        </Text>

        <Text>
          With this plan, you will have unlimited access to a 24/7 online GP, mental health support and
          counseling, digital physiotherapy, and the option to claim back the costs of eye care, dental care,
          and much more!
        </Text>

        <Text>
          Once enrolled, you can add up to 4 children to the plan for free and your partner/spouse for a
          small additional fee.
        </Text>

        <Button
          success
          util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
          onClick={
              isCompleted
                ? () => navigate('/healthcare-cash-plan/confirm')
                : () => navigate('/healthcare-cash-plan/details')
            }
        >
          {isCompleted ? 'Overview' : 'Start: Healthcare cash plan form'}
        </Button>

        <Button
          link
          util={[position.floatRight, width.w12, width.resetSm]}
          onClick={() => navigate('/contact')}
        >
          Ask a question
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default HealthcareCashPlan
