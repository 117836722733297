import React, { useContext } from 'react'
import { navigate } from '@reach/router'
import { Button, Text, margin, padding, position, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import Hero from '../../../../components/Hero'
import Layout from '../../../../components/Layout'

const AxaHealthcare = () => {
  const user = useContext(UserContext)
  const { Loaded, Steps, dependentList } = user
  const userTaskAxaForm = Steps.find(step => step.Step.Id === 9)
  const isCompleted = userTaskAxaForm && userTaskAxaForm.Completed
  return Loaded ? (
    <Layout>
      <Hero icon="person" preTitleMobile="Step 8 of 9">
        Private healthcare
      </Hero>

      <ContentWrap util={[padding.v3, padding.v5Sm]}>
        <Text>
          We’re excited to offer private healthcare to our employees through AXA Health!
        </Text>

        <Text>
          With private healthcare, you’ll get faster access to enhanced medical treatment, including private hospital care, specialist fees, mental health support, and comfortable private rooms.
        </Text>

        <Text>
          You can include your loved ones in this scheme for a small additional fee.
        </Text>


        {dependentList.length > 0 && (
          <Button
            success
            util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
            onClick={
              isCompleted
                ? () => navigate('/axa-healthcare/confirm')
                : () => navigate('/axa-healthcare/details')
            }
          >
            {isCompleted ? 'Overview' : 'Start: Private healthcare form'}
          </Button>
        )}

        {dependentList.length === 0 && (
          <Button
            success
            util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
            onClick={() => navigate('/axa-healthcare/details')}
          >
            {isCompleted ? 'Update' : 'Start: Private healthcare form'}
          </Button>
        )}

        <Button
          link
          util={[position.floatRight, width.w12, width.resetSm]}
          onClick={() => navigate('/contact')}
        >
          Ask a question
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default AxaHealthcare
