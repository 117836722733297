import React, { useState, useContext } from 'react'
import { navigate } from '@reach/router'
import {
  Button,
  color,
  Form,
  Table,
  Text,
  margin,
  List,
  padding,
  position,
  width,
  shape
} from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormRadioGroup from '../../../../components/FormRadioGroup'
import FormTextInput from '../../../../components/FormTextInput'
import FormSelectInput from '../../../../components/FormSelectInput'
import ValidationAlertList from '../../../../components/ValidationAlertList'

import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'

const HealthcareCashPlanDetails = () => {
  const user = useContext(UserContext)
  const {
    Loaded,
    Steps,
    isJoinedHealthcareCashPlan,
    handleUpdateHealthcareCashPlan,
    handleUpdateHealthcareCashPlanOptOutReason,
    handleUpdateHealthcareCashPlanOptOutOtherReason,
    handleSubmitJoinHealthcareCashPlan,
    handleSubmitRefuseTojoinHealthcareCashPlan,
    healthcareCashPlanOptOutReason,
    healthcareCashPlanOptOutOtherReason,
    HealthcareCashPlanValidationErrors
  } = user
  const [isFormUpdated, setIsFormUpdated] = useState(false)
  const userTaskHealthcareCashPlanForm = Steps.find(step => step.Step.Id === 11)

  const handleSubmit = e => {
    e.preventDefault()
  }

  const handleChangeHealthcareCashPlan = e => {
    const { value } = e.target
    setIsFormUpdated(true)
    handleUpdateHealthcareCashPlan(value === 'true')
  }

  const handleChangeHealthcareCashPlanOptOutReason = e => {
    const { value } = e.target
    setIsFormUpdated(true)
    handleUpdateHealthcareCashPlanOptOutReason(value)
  }

  const handleChangeHealthcareCashPlanOptOutOtherReason = e => {
    const { value } = e.target
    setIsFormUpdated(true)
    handleUpdateHealthcareCashPlanOptOutOtherReason(value)
  }

  const submitHealthcareCashPlan = () => {
    setIsFormUpdated(true)
    if (isJoinedHealthcareCashPlan) {
      return handleSubmitJoinHealthcareCashPlan()
    }
    handleSubmitRefuseTojoinHealthcareCashPlan()
  }

  const hasOtherReasonOptedOutWithOutDescription =
    healthcareCashPlanOptOutReason === 4 && healthcareCashPlanOptOutOtherReason === ''

  const hasNotCompletedOptOutAnswers =
    (isJoinedHealthcareCashPlan === false && healthcareCashPlanOptOutReason === 0) ||
    (isJoinedHealthcareCashPlan === false && hasOtherReasonOptedOutWithOutDescription)

  return Loaded ? (
    <Layout>
      {!userTaskHealthcareCashPlanForm.Completed && (
        <Stepper steps={stepperSteps.healthcareCashPlan} currentStep={1} />
      )}

      {HealthcareCashPlanValidationErrors && (
        <ValidationAlertList errors={HealthcareCashPlanValidationErrors} />
      )}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>
            <div>
              <div>
                <Text>
                  Arnold Clark provides this healthcare cash plan as a taxable benefit for all employees.
                </Text>
                <Text h6 as="h6">
                  What does that mean?
                </Text>
                <List util={[margin.b2]}>
                  <List item>Arnold Clark covers the cost of providing this benefit (£35.88 per year).</List>
                  <List item>You will only need to pay the tax on this amount - examples shown below.</List>
                </List>
                <Text>
                  <strong>Example tax amount</strong>
                </Text>
                <Table bordered striped util={[width.w12, margin.b4]}>
                  <Table head>
                    <Table row>
                      <Table util={[color.bgGrey3]} header>
                        Tax rate
                      </Table>
                      <Table util={[color.bgGrey3]} header>
                        Payment
                      </Table>
                    </Table>
                  </Table>
                  <Table body id="users">
                    <Table row key="1">
                      <td style={{ verticalAlign: 'middle' }}>
                        <p style={{ marginBottom: '0' }}>20%</p>
                      </td>
                      <Table cell>
                        <p
                          style={{ marginBottom: '0', color: '#155991', fontWeight: '500', fontSize: '12px' }}
                        >
                          £0.60 per month
                        </p>
                        <p style={{ marginBottom: '0', color: '#999999', fontSize: '12px' }}>
                          £7.18 per year
                        </p>
                      </Table>
                    </Table>
                    <Table row key="2">
                      <td style={{ verticalAlign: 'middle' }}>
                        <p style={{ marginBottom: '0' }}>40%</p>
                      </td>
                      <Table cell>
                        <p
                          style={{ marginBottom: '0', color: '#155991', fontWeight: '500', fontSize: '12px' }}
                        >
                          £1.20 per month
                        </p>
                        <p style={{ marginBottom: '0', color: '#999999', fontSize: '12px' }}>
                          £14.36 per year
                        </p>
                      </Table>
                    </Table>
                  </Table>
                </Table>

                <Text h6 as="h6">
                  Adding your family
                </Text>
                <List util={[margin.b2]}>
                  <List item>You can add up to 4 children under the age of 21 for free.</List>
                  <List item>You can add your partner for £7.50 per month.</List>
                  <List item>Each member on your policy can enjoy the benefits of Simplyhealth.</List>
                  <List item>
                    Please contact Simplyhealth once you have your enrollment documents to do this.
                  </List>
                </List>

                <Text util={[margin.t5]}>
                  For full details, please download the{' '}
                  <a href="/www.arnoldclark.com/simplyhealth" target="blank">
                    www.arnoldclark.com/simplyhealth
                  </a>
                  .
                </Text>

                <FormRadioGroup
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                      checked: isJoinedHealthcareCashPlan
                    },
                    {
                      label: 'No',
                      value: false,
                      checked: isJoinedHealthcareCashPlan === false
                    }
                  ]}
                  name=""
                  onChange={handleChangeHealthcareCashPlan}
                  label="Would you like to be enrolled in the Simplyhealth cash plan?"
                  data-format="bool"
                />
              </div>
              {isJoinedHealthcareCashPlan === false && (
                <div>
                  <FormSelectInput
                    id="HealthcareCashPlanOptOutReason"
                    label="Why have you opted out?"
                    name="HealthcareCashPlanOptOutReason"
                    util={margin.b2}
                    onChange={handleChangeHealthcareCashPlanOptOutReason}
                  >
                    <option value="0">Please select...</option>
                    <option value="1">I already have access to a virtual GP/cash plan</option>
                    <option value="2">Cost</option>
                    <option value="3">Prefer not to say</option>
                    <option value="4">Other</option>
                  </FormSelectInput>
                  {healthcareCashPlanOptOutReason === 4 && (
                    <FormTextInput
                      name="HealthcareCashPlanOptOutOtherReason"
                      type="text"
                      label="Please describe"
                      onChange={handleChangeHealthcareCashPlanOptOutOtherReason}
                    />
                  )}
                </div>
              )}
            </div>
          </Panel>

          <Button
            type="buttom"
            onClick={submitHealthcareCashPlan}
            success
            disabled={
              isJoinedHealthcareCashPlan === '' ||
              hasNotCompletedOptOutAnswers ||
              (isFormUpdated === false && userTaskHealthcareCashPlanForm.Completed)
            }
            util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
          >
            {userTaskHealthcareCashPlanForm.Completed ? 'Update' : 'Confirm: Healthcare cash plan details'}
          </Button>
          <Button
            link
            util={[position.floatRight, width.w12, width.resetSm]}
            onClick={() => navigate('/contact')}
          >
            Ask a question
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default HealthcareCashPlanDetails
