import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'chassis-react'

const FormTextInput = props => {
  const {
    id,
    label,
    hint,
    afterText,
    error,
    name,
    textarea,
    children,
    util,
    autoComplete,
    ...rest
  } = props

  return (
    <Form group error={!!error && true}>
      {label && (
        <Form label htmlFor={id}>
          {label}
        </Form>
      )}

      {hint && <Form hint>{hint}</Form>}

      {!!error && <Form validation>{error}</Form>}

      {textarea ? (
        <Form textarea id={name || id} util={util} name={name} autoComplete={autoComplete} {...rest}>
          {children}
        </Form>
      ) : (
        <Form input id={name || id} util={util} name={name} autoComplete={autoComplete} {...rest} />
      )}
      {afterText && (
        <Form label style={{ marginLeft: '5px', marginTop: '-3px' }}>
          {afterText}
        </Form>
      )}
    </Form>
  )
}

FormTextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  afterText: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.string,
  textarea: PropTypes.bool,
  name: PropTypes.string,
  util: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
  autoComplete: PropTypes.bool
}

export default FormTextInput
