import React, { useContext } from 'react'
import { Button, Text, margin, position, width, List } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import Layout from '../../../../components/Layout'
import Stepper from '../../../../components/Stepper'

const Confirm = () => {
  const user = useContext(UserContext)

  const { Loaded, Steps, handleUpdateStep, isJoinedHealthcareCashPlan } = user


  const userTaskHealthcareCashPlanForm = Steps.find(step => step.Step.Id === 11)

  return Loaded ? (
    <Layout>
      {!userTaskHealthcareCashPlanForm.Completed && (
        <Stepper steps={stepperSteps.healthcareCashPlan} currentStep={2} />
      )}

      <ContentWrap pad>
        {isJoinedHealthcareCashPlan ? (
          <div>
            <Text>You have opted in to Simplyhealth.</Text>
            <Text h6 as="h6">
              What happens next?
            </Text>
            <List util={[margin.b2]}>
              <List item>You will be enrolled from the 1st of the month following your start date.</List>
              <List item>
                Your documents will be sent to your personal email address once you’re enrolled.
              </List>
            </List>
          </div>
        ) : (
          <div>
            <Text>You have opted out of Simplyhealth.</Text>
            <Text>You can opt in once you have started with us if you change your mind.</Text>
          </div>
        )}

        <Button
          success
          util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
          onClick={() => handleUpdateStep(userTaskHealthcareCashPlanForm.Id)}
        >
          Confirm
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default Confirm
