import React, { useState, useContext } from 'react'
import { navigate } from '@reach/router'
import {
  Button,
  Card,
  display,
  text,
  color,
  Form,
  Grid,
  Table,
  Text,
  margin,
  List,
  padding,
  position,
  width,
  shape
} from 'chassis-react'
import moment from 'moment'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormRadioGroup from '../../../../components/FormRadioGroup'
import FormTextInput from '../../../../components/FormTextInput'
import FormSelectInput from '../../../../components/FormSelectInput'
import ValidationAlertList from '../../../../components/ValidationAlertList'
import Pdf from '../../../../static/files/AXAHealthSummary.pdf'

import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'
import relationship from './helper/index'

const History = () => {
  const user = useContext(UserContext)
  const [isAgreedToAddSpouse, setAgreeToAddSpouse] = useState('')
  const [Firstname, setFirstname] = useState('')
  const [Surname, setSurname] = useState('')
  const [Relationship, setRelationship] = useState('')
  const [DateOfBirth, setDateOfBirth] = useState('')
  const [Gender, setGender] = useState('')
  const [isFormUpdated, setIsFormUpdated] = useState(false)
  const {
    Loaded,
    handleAddDependents,
    handleAddNewDependent,
    handleDeleteDependent,
    Steps,
    dependentList,
    handleSubmitJoinAxaHealthcareWithoutDependents,
    handleSubmitJoinAxaHealthcareWithDependents,
    handleSubmitRefuseTojoinAxaHealthcare,
    handleUpdateJoinAxaHealthcare,
    handleUpdateAxaOptOutReason,
    handleUpdateAxaOptOutOtherReason,
    isJoinedAxaHealthcare,
    AxaHealthCareValidationErrors,
    axaOptOutReason,
    axaOptOutOtherReason
  } = user
  const userTaskAxaForm = Steps.find(step => step.Step.Id === 9)

  const handleSubmitFormWithDependents = () => {
    handleSubmitJoinAxaHealthcareWithDependents()
  }

  const handleSubmit = e => {
    e.preventDefault()
    handleAddDependents(dependentList)
  }

  const resetMemberForm = () => {
    setFirstname('')
    setSurname('')
    setDateOfBirth('')
    setGender('')
    setRelationship('')
  }

  const handleChangeSpouse = e => {
    const { value } = e.target
    setIsFormUpdated(true)
    setAgreeToAddSpouse(value === 'true')
  }
  const handleChangeAxa = e => {
    const { value } = e.target
    setIsFormUpdated(true)
    handleUpdateJoinAxaHealthcare(value === 'true')
  }

  const handleChangeAxaOptOutReason = e => {
    const { value } = e.target
    setIsFormUpdated(true)
    handleUpdateAxaOptOutReason(value)
  }

  const handleChangeAxaOptOutOtherReason = e => {
    const { value } = e.target
    setIsFormUpdated(true)
    handleUpdateAxaOptOutOtherReason(value)
  }

  const handleChangeFirstname = e => {
    e.preventDefault()
    const { value } = e.target
    setFirstname(value)
  }

  const handleChangeSurname = e => {
    e.preventDefault()
    const { value } = e.target
    setSurname(value)
  }

  const handleChangeRelationship = e => {
    e.preventDefault()
    const { value } = e.target
    setRelationship(value)
  }

  const handleChangeGender = e => {
    const { value } = e.target

    setGender(value)
  }
  const handleChangeDOB = e => {
    e.preventDefault()
    const { value } = e.target
    setDateOfBirth(value)
  }

  const handleAddMember = () => {
    setIsFormUpdated(true)

    handleAddNewDependent({
      Id: 0,
      Firstname,
      Surname,
      Relationship,
      DateOfBirth,
      Gender
    })
    resetMemberForm()
  }

  const handleDelete = i => {
    setIsFormUpdated(true)
    handleDeleteDependent(i)
  }

  const submitAxa = () => {
    setIsFormUpdated(true)
    if (isJoinedAxaHealthcare) {
      if (isAgreedToAddSpouse) {
        return handleSubmitFormWithDependents()
      }
      return handleSubmitJoinAxaHealthcareWithoutDependents()
    }
    handleSubmitRefuseTojoinAxaHealthcare()
  }

  const hasOtherReasonOptedOutWithOutDescription = axaOptOutReason === 5 && axaOptOutOtherReason === ''

  const hasNotCompletedOptOutAnswers =
    (isJoinedAxaHealthcare === false && axaOptOutReason === 0) ||
    (isJoinedAxaHealthcare === false && hasOtherReasonOptedOutWithOutDescription)
  return Loaded ? (
    <Layout>
      {!userTaskAxaForm.Completed && <Stepper steps={stepperSteps.healthCare} currentStep={1} />}

      {AxaHealthCareValidationErrors && <ValidationAlertList errors={AxaHealthCareValidationErrors} />}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>
            <div>
              <div>
                <Text>Arnold Clark provides private healthcare as a taxable benefit for all employees.</Text>
                <Text h6 as="h6">
                  What does that mean?
                </Text>
                <List util={[margin.b2]}>
                  <List item>Arnold Clark covers the cost of providing this benefit (£227 per year).</List>
                  <List item>You will only need to pay the tax on this amount - examples shown below.</List>
                </List>
                <Text>
                  <strong>Example tax amount</strong>
                </Text>
                <Table bordered striped util={width.w12}>
                  <Table head>
                    <Table row>
                      <Table util={[color.bgGrey3]} header>
                        Tax rate
                      </Table>
                      <Table util={[color.bgGrey3]} header>
                        Payment
                      </Table>
                    </Table>
                  </Table>
                  <Table body id="users">
                    <Table row key="1">
                      <td style={{ verticalAlign: 'middle' }}>
                        <p style={{ marginBottom: '0' }}>20%</p>
                      </td>
                      <Table cell>
                        <p
                          style={{ marginBottom: '0', color: '#155991', fontWeight: '500', fontSize: '12px' }}
                        >
                          £3.78 per month
                        </p>
                        <p style={{ marginBottom: '0', color: '#999999', fontSize: '12px' }}>
                          £45.36 per year
                        </p>
                      </Table>
                    </Table>
                    <Table row key="2">
                      <td style={{ verticalAlign: 'middle' }}>
                        <p style={{ marginBottom: '0' }}>40%</p>
                      </td>
                      <Table cell>
                        <p
                          style={{ marginBottom: '0', color: '#155991', fontWeight: '500', fontSize: '12px' }}
                        >
                          £7.56 per month
                        </p>
                        <p style={{ marginBottom: '0', color: '#999999', fontSize: '12px' }}>
                          £90.72 per year
                        </p>
                      </Table>
                    </Table>
                  </Table>
                </Table>

                <Text util={[margin.t5]}>
                  For full details, please download the{' '}
                  <a href={Pdf} target="blank">
                    AXA Terms and Conditions
                  </a>
                  .
                </Text>

                <FormRadioGroup
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                      checked: isJoinedAxaHealthcare
                    },
                    {
                      label: 'No',
                      value: false,
                      checked: isJoinedAxaHealthcare === false
                    }
                  ]}
                  name="OtherSignificantHealthProblems"
                  onChange={handleChangeAxa}
                  label="Would you like to be enrolled in AXA Health?"
                  data-format="bool"
                />
              </div>
              {isJoinedAxaHealthcare === false && (
                <div>
                  <FormSelectInput
                    id="AxaOptOutReason"
                    label="Why have you opted out?"
                    name="AxaOptOutReason"
                    util={margin.b2}
                    onChange={handleChangeAxaOptOutReason}
                  >
                    <option value="0">Please select...</option>
                    <option value="1">I already have private healthcare</option>
                    <option value="2">Cost</option>
                    <option value="3">I am satisfied with NHS treatment</option>
                    <option value="4">Prefer not to say</option>
                    <option value="5">Other</option>
                  </FormSelectInput>
                  {axaOptOutReason === 5 && (
                    <FormTextInput
                      name="OptOutOtherReason"
                      type="text"
                      label="Please describe"
                      onChange={handleChangeAxaOptOutOtherReason}
                    />
                  )}
                </div>
              )}

              {isJoinedAxaHealthcare && (
                <div>
                  <FormRadioGroup
                    options={[
                      {
                        label: 'Yes',
                        value: true,
                        checked: isAgreedToAddSpouse === true
                      },
                      {
                        label: 'No',
                        value: false,
                        checked: isAgreedToAddSpouse === false
                      }
                    ]}
                    name="spouse"
                    onChange={handleChangeSpouse}
                    data-format="bool"
                    label="Would you like to add family members to your AXA Health policy?"
                  />
                  {isAgreedToAddSpouse === false ||
                  (isAgreedToAddSpouse === '' && dependentList.length === 0) ? (
                    ''
                  ) : (
                    <>
                      <Text>Family memebers may be added to your policy if they meet these criteria:</Text>
                      <List util={[margin.b2]}>
                        <List item>They must live at the same address</List>
                        <List item>Spouse partner must be under 65</List>
                        <List item>Children must be under 21</List>
                      </List>

                      <Table bordered striped util={width.w12}>
                        <Table head>
                          <Table row />
                          <Table util={[color.bgGrey3]} header>
                            Family member
                          </Table>
                          <Table util={[color.bgGrey3]} header>
                            Additional cost
                          </Table>
                        </Table>
                        <Table body id="users">
                          <Table row key="1">
                            <td style={{ verticalAlign: 'middle' }}>
                              <p style={{ marginBottom: '0' }}>Children only</p>
                            </td>
                            <Table cell>
                              <p
                                style={{
                                  marginBottom: '0',
                                  color: '#155991',
                                  fontWeight: '500',
                                  fontSize: '12px'
                                }}
                              >
                                £15.75 per month
                              </p>
                              <p style={{ marginBottom: '0', color: '#999999', fontSize: '12px' }}>
                                £189 per year
                              </p>
                            </Table>
                          </Table>
                          <Table row key="2">
                            <td style={{ verticalAlign: 'middle' }}>
                              <p style={{ marginBottom: '0' }}>Spouse / Partner only</p>
                            </td>
                            <Table cell>
                              <p
                                style={{
                                  marginBottom: '0',
                                  color: '#155991',
                                  fontWeight: '500',
                                  fontSize: '12px'
                                }}
                              >
                                £19.42 per month
                              </p>
                              <p style={{ marginBottom: '0', color: '#999999', fontSize: '12px' }}>
                                £233.04 per year
                              </p>
                            </Table>
                          </Table>
                          <Table row key="3">
                            <td style={{ verticalAlign: 'middle' }}>
                              <p style={{ marginBottom: '0' }}>Spouse / Partner and children</p>
                            </td>

                            <Table cell>
                              <p
                                style={{
                                  marginBottom: '0',
                                  color: '#155991',
                                  fontWeight: '500',
                                  fontSize: '12px'
                                }}
                              >
                                £31.50 per month
                              </p>
                              <p style={{ marginBottom: '0', color: '#999999', fontSize: '12px' }}>
                                £378 per year
                              </p>
                            </Table>
                          </Table>
                          <Table row key="4">
                            <Table cell colspan="2">
                              Additional payments are deducted from your wages each month.
                            </Table>
                          </Table>
                        </Table>
                      </Table>

                      <Grid row>
                        <Grid colSm={6}>
                          <Text h6 as="h6" util={[margin.b3, margin.t5]}>
                            Please provide details
                          </Text>
                          <FormTextInput
                            label="First name"
                            name="Firstname"
                            onChange={handleChangeFirstname}
                            value={Firstname}
                            disabled={!isAgreedToAddSpouse}
                            autoComplete="off"
                          />

                          <FormTextInput
                            label="Last name"
                            name="Surname"
                            onChange={handleChangeSurname}
                            value={Surname}
                            disabled={!isAgreedToAddSpouse}
                            autoComplete="off"
                          />

                          <FormSelectInput
                            name="AddressSelect"
                            label="Relationship to eligible employee"
                            onChange={handleChangeRelationship}
                            value={Relationship}
                            disabled={!isAgreedToAddSpouse}
                            autoComplete="off"
                          >
                            <option value="" disabled={!isAgreedToAddSpouse}>
                              Please select...
                            </option>
                            {relationship.map((item, i) => (
                              <option key={i} value={item}>
                                {item}
                              </option>
                            ))}
                          </FormSelectInput>

                          <FormTextInput
                            name="DateOfBirth"
                            type="date"
                            label="Date of birth (DD/MM/YYYY)"
                            value={DateOfBirth}
                            onChange={handleChangeDOB}
                            placeholder="yyyy-mm-dd"
                            pattern="\d{4}-\d{2}-\d{2}"
                            disabled={!isAgreedToAddSpouse}
                            autoComplete="off"
                            max={moment.utc().format('YYYY-MM-DD')}
                          />

                          <FormRadioGroup
                            options={[
                              {
                                label: 'Male',
                                value: 'Male',
                                checked: Gender !== '' && Gender !== 'Female'
                              },
                              {
                                label: 'Female',
                                value: 'Female',
                                checked: Gender !== '' && Gender !== 'Male'
                              }
                            ]}
                            disabled={!isAgreedToAddSpouse}
                            name="Gender"
                            id="Gender"
                            label="Sex"
                            onChange={handleChangeGender}
                          />

                          <Button
                            primary
                            util={margin.t1}
                            type="button"
                            onClick={handleAddMember}
                            disabled={!Firstname || !Surname || !Relationship || !DateOfBirth || !Gender}
                          >
                            {dependentList.length > 0 ? 'Add another member to your policy' : 'Add member'}
                          </Button>

                          {dependentList &&
                            dependentList.length > 0 &&
                            dependentList.map((item, i) => (
                              <ul>
                                <li key={i}>
                                  <Card util={margin.t4}>
                                    <Card content>
                                      <strong>First name</strong>
                                      <br />
                                      {item.Firstname}
                                    </Card>

                                    <Card content>
                                      <strong>Last name</strong>
                                      <br />
                                      {item.Surname}
                                    </Card>

                                    <Card content>
                                      <strong>Relationship</strong>
                                      <br />
                                      {item.Relationship}
                                    </Card>

                                    <Card content>
                                      <strong>Date of birth</strong>
                                      <br />
                                      {item.DateOfBirth}
                                    </Card>

                                    <Card content>
                                      <strong>Gender</strong>
                                      <br />
                                      {item.Gender}
                                    </Card>

                                    <Card content util={text.right}>
                                      <Button
                                        type="button"
                                        link
                                        danger
                                        small
                                        util={[display.inlineBlock, color.acRed]}
                                        onClick={() => handleDelete(i)}
                                      >
                                        Remove
                                      </Button>
                                    </Card>
                                  </Card>
                                </li>
                              </ul>
                            ))}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </div>
              )}
            </div>
          </Panel>

          <Button
            type="buttom"
            onClick={submitAxa}
            success
            disabled={
              isJoinedAxaHealthcare === '' ||
              hasNotCompletedOptOutAnswers ||
              (isFormUpdated === false && userTaskAxaForm.Completed) ||
              (isAgreedToAddSpouse && dependentList.length === 0)
            }
            util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
          >
            {userTaskAxaForm.Completed ? 'Update' : 'Confirm: Private healthcare details'}
          </Button>
          <Button
            link
            util={[position.floatRight, width.w12, width.resetSm]}
            onClick={() => navigate('/contact')}
          >
            Ask a question
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default History
