import React, { useContext } from 'react'
import {
  Base,
  Button,
  Form,
  Text,
  border,
  color,
  margin,
  padding,
  position,
  shape,
  width,
  List
} from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import DetailsList from '../../../../components/DetailsList'
import Layout from '../../../../components/Layout'
import Stepper from '../../../../components/Stepper'

const Confirm = () => {
  const user = useContext(UserContext)

  const { Loaded, Steps, handleUpdateStep, dependentList, isJoinedAxaHealthcare } = user

  const userTaskAxaForm = Steps.find(step => step.Step.Id === 9)

  const overview = ({ Firstname, Surname, relationship, dob, gender }) => [
    {
      dt: 'Firstname',
      dd: Firstname
    },
    {
      dt: 'Surname',
      dd: Surname
    },
    {
      dt: 'Relationship',
      dd: relationship
    },
    {
      dt: 'Date of birth',
      dd: dob
    },
    {
      dt: 'Gender',
      dd: gender
    }
  ]

  return Loaded ? (
    <Layout>
      {!userTaskAxaForm.Completed && <Stepper steps={stepperSteps.healthCare} currentStep={3} />}

      <ContentWrap pad>
        {isJoinedAxaHealthcare ? (
          <div>
            {dependentList.length > 0 ? (
              <div>
                <Text>You have opted in to AXA Health.</Text>
                <Text h6 as="h6">
                  What happens next?
                </Text>
                <List util={[margin.b2]}>
                  <List item>You will be enrolled from the 1st of the month following your start date.</List>
                  <List item>Documents will be sent to your home address, once you’re enrolled.</List>
                </List>
                <Text h6>Please confirm the details of all the additonal members to your policy.</Text>
                <Form>
                  <Base
                    util={[
                      padding.v4,
                      padding.h3,
                      color.bgWhite,
                      border.a1,
                      color.bcGrey3,
                      shape.rounded,
                      margin.b3
                    ]}
                  >
                    {dependentList.map((item, i) => (
                      <DetailsList
                        title={`Person ${i + 1}`}
                        editLink="/axa-healthcare/details"
                        listItems={overview({
                          Firstname: item.Firstname,
                          Surname: item.Surname,
                          dob: item.DateOfBirth,
                          relationship: item.Relationship,
                          gender: item.Gender
                        })}
                      />
                    ))}
                  </Base>
                </Form>
              </div>
            ) : (
              <div>
                <Text>You have opted in to AXA Health.</Text>
                <Text h6 as="h6">
                  What happens next?
                </Text>
                <List util={[margin.b2]}>
                  <List item>You will be enrolled from the 1st of the month following your start date.</List>
                  <List item>
                    Your documents will be sent to your personal email address once you’re enrolled.
                  </List>
                </List>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div>
              <Text>You have opted out of AXA Health.</Text>
              <Text>You can opt in once you have started with us if you change your mind.</Text>
            </div>
          </div>
        )}

        <Button
          success
          util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}
          onClick={() => handleUpdateStep(userTaskAxaForm.Id)}
        >
          Confirm
        </Button>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default Confirm
